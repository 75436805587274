var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showButton)?_c('v-btn',{class:{ 'menu-btn': !_vm.mini, 'menu-btn-mini': _vm.mini },attrs:{"icon":"","elevation":"2"},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[(_vm.mini == false)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('v-navigation-drawer',{class:{ 'mini-sidebar': _vm.mini },attrs:{"app":"","color":"appSideBar","mini-variant":_vm.mini,"temporary":_vm.$vuetify.breakpoint.smAndDown,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"width":"220"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"text-center"},[(_vm.mini == false)?_c('v-img',{staticClass:"logo-img",attrs:{"width":"135px","height":"39px","src":require("@/assets/logo/facia.png")}}):_c('v-img',{staticClass:"ml-2 my-3 logo-img-mini",attrs:{"width":"36px","height":"39px","src":require("@/assets/logo/facia-face.png")}})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-list-item-group',_vm._l((_vm.getSideBarItems.items),function(category,index){return _c('div',{key:index},[(
              Object.keys(category)[0] != 'Tracking' &&
              Object.keys(category)[0] !== 'main'
            )?[(_vm.mini === false)?_c('span',{},[_c('svg',{staticClass:"divider-class",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"220","height":"2","viewBox":"0 0 196 2","fill":"none"}},[_c('path',{attrs:{"d":"M0 1H196","stroke":"#E3E3E3","stroke-dasharray":"3 3"}})])]):_c('span',{staticClass:"ml-3"},[_c('svg',{attrs:{"width":"32","height":"2","viewBox":"0 0 32 2","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 1H32","stroke":"#E3E3E3","stroke-dasharray":"3 3"}})])]),(_vm.mini === false)?_c('h5',{staticClass:"sectionText",class:{ 'mb-1': !_vm.mini, 'rounded-1': !_vm.mini }},[_vm._v(" "+_vm._s(Object.keys(category)[0].replace(/_/g, " "))+" ")]):_vm._e()]:_vm._e(),_vm._l((Object.values(category)[0]),function(item,itemIndex){return _c('v-list-item',_vm._b({key:itemIndex,class:{
              'ml-3': !_vm.mini,
              'mb-1': !_vm.mini,
              'mini-item': _vm.mini,
              'rounded-1': !_vm.mini,
            },attrs:{"link":""}},'v-list-item',item.attrs,false),[_c('v-list-item-icon',{class:{ icon: _vm.mini }},[_c('v-img',{staticClass:"mt-1 side-icon",attrs:{"max-width":"16","max-height":"16","src":item.img}})],1),(!_vm.mini)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"sidebar-title letter-space"},[_vm._v(_vm._s(item.title))])],1):_vm._e()],1)})],2)}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }