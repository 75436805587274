<template>
  <div>
    <v-app-bar app color="appBackGround" elevation="0">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="toggleDrawer"
      ></v-app-bar-nav-icon>
      <div class="fw-600 ml-3 font-15 greyColor--text">
        {{
          $route.name != null
            ? $route.name.replace(/([A-Z])/g, "$1").trim()
            : ""
        }}
      </div>
      <v-spacer></v-spacer>
      <v-menu rounded="lg" left bottom plain nudge-left="20" nudge-bottom="40">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            width="120px"
            text
            icon
            plain
            v-bind="attrs"
            v-on="on"
            :ripple="false"
            class="text-transform-none letter-space navUser--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="text-card-title text-capitalize"
                >
                  {{ userNameGetter.substring(0, 10) + "..." }}
                </span>
              </template>
              <span class="text-card-hover text-capitalize">{{
                userNameGetter
              }}</span>
            </v-tooltip>

            <v-icon color="navDropDown">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <template>
          <v-card class="pa-1" width="190">
            <div v-for="(item, i) in listItems" :key="i" class="py-1">
              <v-btn
                :to="item.path"
                text
                class="list-item d-flex justify-start text-capitalize"
                :active-class="'list-item-active'"
              >
                <v-img
                  class="mr-2"
                  max-width="14"
                  max-height="14"
                  :src="item.icon"
                  :class="{ 'active-image': $route.path === item.path }"
                ></v-img>
                <span
                  :class="{ 'active-text': $route.path === item.path }"
                  class="text-card-title"
                  v-text="item.text"
                ></span>
              </v-btn>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="pl-3"
              width="170"
              height="2"
              viewBox="0 0 196 2"
              fill="none"
            >
              <path d="M0 1H196" stroke="#E3E3E3" stroke-dasharray="3 3" />
            </svg>
            <v-btn
              @click="openModal"
              text
              class="text-left list-item"
              :active-class="'list-item-active text-left'"
              style="width: 100%"
            >
              <v-img
                class="mr-2 align-start"
                max-width="14"
                max-height="14"
                src="../../assets/icons/Group.svg"
                :class="{ 'active-image': $route.path === '/logout' }"
              ></v-img>
              <span class="text-card-title text-capitalize">Logout</span>
            </v-btn>
          </v-card>
        </template>
      </v-menu>
      <v-avatar color="navAvatar" size="31" class="">
        <span v-if="userImageGetter == null" class="font-12 fw-500">{{
          nameInitial
        }}</span>
        <v-img width="31" height="31" v-else :src="userImageGetter"></v-img>
      </v-avatar>
    </v-app-bar>
    <v-divider></v-divider>
    <div class="text-center">
      <v-dialog v-model="dialog" width="410" class="dialog" ref="dialogRef">
        <v-card>
          <v-card-title class="font-16 fw-600 greyColor--text logout-title">
            Confirm Logout
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="font-12 fw-500 secondary--text mt-3">
            Are you sure you want to logout? This will invalidate all of your
            sessions and you will need to re-login.
          </v-card-text>

          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              class="text-transform-none logout-btn cancelBtnText--text font-12 fw-500"
              height="34px"
              min-width="95px"
              color="#F4F6FA"
              elevation="0"
              @click="cancelDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text-transform-none logout-btn textColor--text font-12 fw-500"
              height="34px"
              min-width="95px"
              color="primary"
              elevation="0"
              :loading="logoutLoader"
              @click="logout()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <side-bar></side-bar>
  </div>
</template>

<script>
import ApiService from "../../services/Api";
import { showErrorMessage } from "@/utils/showError";
import SideBar from "./SideBar.vue";
import { mapGetters } from "vuex";
export default {
  name: "NavBar",
  components: { SideBar },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      drawer: false,
      logoutModal: false,
      logoutLoader: false,
      dialog: false,
      listItems: [
        {
          text: "User Management",
          icon: require("../..//assets/icons/user_managment.svg"),
          path: "/user-management",
        },
        {
          text: "Account Settings",
          icon: require("../../assets/icons/account-setting.svg"),
          path: "/settings",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userNameGetter", "userImageGetter"]),

    /**
     * Generates initials from the user's full name. It splits the full name into parts,
     * takes the first character of the first part and the first character of the last part,
     * and returns them as initials. If the initials are of type string, it returns them in uppercase.
     *
     * @method nameInitial
     * @returns {String} The initials of the user's full name.
     */
    nameInitial() {
      const fullName = this.userNameGetter.split(" ");
      const initials = fullName.shift()?.charAt(0) + fullName.pop()?.charAt(0);
      if (typeof initials === "string") return initials.toUpperCase();
      return initials;
    },
  },
  methods: {
    /**
     * Navigates back to the transactions page by pushing the "/transactions" route
     * to the router history.
     *
     * @method goBack
     */
    goBack() {
      this.$router.push("/transactions");
    },

    /**
     * Toggles the drawer visibility by emitting a "toggle-drawer" event with the drawer state.
     *
     * @method toggleDrawer
     */
    toggleDrawer() {
      this.$root.$emit("toggle-drawer", (this.drawer = true));
    },

    /**
     * Opens the logout modal by calling the openModal method on the logout modal reference.
     *
     * @method openLogoutModal
     */
    openLogoutModal() {
      this.$refs.logoutModalRef.openModal();
    },

    /**
     * Initiates the logout process. Sets the logoutLoader to true to indicate loading,
     * sends a GET request to "/merchant/logout" endpoint to logout the user. Upon success,
     * closes the dialog, resets the logoutLoader, dispatches logout action to the store,
     * sets logout state, and navigates the user to the home page. If an error occurs,
     * displays an error message, closes the dialog, resets the logoutLoader, and emits a "cancel" event.
     *
     * @method logout
     */
    logout() {
      this.logoutLoader = true;
      ApiService.GetRequest("/merchant/logout")
        .then(async () => {
          this.dialog = false;
          this.logoutLoader = false;
          this.$store.dispatch("logout");
          this.$store.dispatch("SET_LOGOUT");
          this.$router.push("/");
        })
        .catch((err) => {
          showErrorMessage(err);
          this.dialog = false;
          this.logoutLoader = false;
          this.$emit("cancel");
        });
    },

    /**
     * Opens the logout confirmation modal by setting the dialog state to true.
     *
     * @method openModal
     */
    openModal() {
      this.dialog = true;
    },

    /**
     * Cancels the logout confirmation dialog by setting the dialog state to false
     * and emitting a "cancel" event.
     *
     * @method cancelDialog
     */
    cancelDialog() {
      this.dialog = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none !important;
}

.letter-space {
  letter-spacing: 0 !important;
}

.v-toolbar__content {
  margin: 0px;
  border-bottom: 1px solid rgba(232, 235, 246, 0.6) !important;
}

.nav-list:hover {
  background: rgba(36, 157, 168, 0.1) !important;
  border-radius: 4px;
}

.app-bar-custom {
  max-width: 85% !important;
  left: 215px !important;
}

.nav-list {
  min-height: 32px !important;
}

.routerLink {
  text-decoration: none;
  color: black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.logout-title {
  padding: 6px 23px 5px !important;
}

.logout-btn {
  border-radius: 5px;
}

.dialog {
  z-index: 50 !important;
}

.routerLink:hover {
  background: #eeeef3;
  cursor: pointer;
}

.cursor-pointer:hover {
  background: #eeeef3;
  cursor: pointer;
}

.active-text {
  color: #173dd0 !important;
  font-size: 13px !important;
}

.active-image {
  filter: invert(29%) sepia(99%) saturate(2286%) hue-rotate(224deg)
    brightness(74%) contrast(120%);
}

.font-10 {
  font-size: 10px !important;
}
.list-item-active {
  color: #eeeef3 !important;
}
.list-item {
  width: 100%;
}

.list-item .v-btn__content {
  justify-content: flex-start;
}

.image-filter {
  filter: invert(20%) sepia(100%) saturate(2000%) hue-rotate(0deg)
    brightness(80%) contrast(120%);
}

.text-left {
  text-align: left !important;
}
.back-btn {
  color: #292b30;

  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-card-title {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  font-size: 13px;
  font-weight: 400;
}
.text-card-hover {
  color: #eeeef3;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  font-size: 13px;
  font-weight: 400;
}
</style>
