<template>
  <div>
    <nav-bar></nav-bar>
    <keep-alive include="TransactionReports">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import NavBar from "../components/common/NavBar.vue";
export default {
  components: {
    NavBar,
  },
};
</script>
