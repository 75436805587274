<template>
  <div>
    <v-btn
      v-if="showButton"
      icon
      @click.stop="mini = !mini"
      :class="{ 'menu-btn': !mini, 'menu-btn-mini': mini }"
      elevation="2"
    >
      <v-icon v-if="mini == false">mdi-chevron-left</v-icon>
      <v-icon v-else>mdi-chevron-right</v-icon>
    </v-btn>
    <v-navigation-drawer
      app
      color="appSideBar"
      v-model="drawer"
      :mini-variant.sync="mini"
      :temporary="$vuetify.breakpoint.smAndDown"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :class="{ 'mini-sidebar': mini }"
      width="220"
    >
      <div class="text-center">
        <v-img
          class="logo-img"
          width="135px"
          height="39px"
          src="@/assets/logo/facia.png"
          v-if="mini == false"
        ></v-img>
        <v-img
          class="ml-2 my-3 logo-img-mini"
          width="36px"
          height="39px"
          src="@/assets/logo/facia-face.png"
          v-else
        ></v-img>
      </div>
      <v-divider class="mx-4"></v-divider>
      <v-list flat dense>
        <v-list-item-group>
          <div v-for="(category, index) in getSideBarItems.items" :key="index">
            <!-- Check if the category is "main" -->
            <template
              v-if="
                Object.keys(category)[0] != 'Tracking' &&
                Object.keys(category)[0] !== 'main'
              "
            >
              <span class="" v-if="mini === false">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="divider-class"
                  width="220"
                  height="2"
                  viewBox="0 0 196 2"
                  fill="none"
                >
                  <path d="M0 1H196" stroke="#E3E3E3" stroke-dasharray="3 3" />
                </svg>
              </span>
              <span class="ml-3" v-else>
                <svg
                  width="32"
                  height="2"
                  viewBox="0 0 32 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1H32" stroke="#E3E3E3" stroke-dasharray="3 3" />
                </svg>
              </span>
              <h5
                v-if="mini === false"
                :class="{ 'mb-1': !mini, 'rounded-1': !mini }"
                class="sectionText"
              >
                {{ Object.keys(category)[0].replace(/_/g, " ") }}
              </h5>
            </template>
            <v-list-item
              :class="{
                'ml-3': !mini,
                'mb-1': !mini,
                'mini-item': mini,
                'rounded-1': !mini,
              }"
              v-for="(item, itemIndex) in Object.values(category)[0]"
              :key="itemIndex"
              v-bind="item.attrs"
              link
            >
              <v-list-item-icon :class="{ icon: mini }">
                <v-img
                  class="mt-1 side-icon"
                  max-width="16"
                  max-height="16"
                  :src="item.img"
                ></v-img>
              </v-list-item-icon>
              <v-list-item-content v-if="!mini">
                <v-list-item-title class="sidebar-title letter-space">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      activeTitle: "",
      logoutModal: false,
      mini: false,
      value: false,
    };
  },
  computed: {
    ...mapGetters(["merchantRole", "permissions", "getSideBarItems"]),
    showButton() {
      // You can adjust the breakpoint according to your needs
      return this.$vuetify.breakpoint.mdAndUp;
    },

    /**
     * Property to check if breakpoint is small or medium
     */
    mobile() {
      const breakP = ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
      return breakP;
    },
  },
  watch: {
    /**
     * Watcher to check if dashboard is in mobile view, it will set drawer to false
     */
    mobile() {
      return this.mobile ? (this.drawer = false) : (this.drawer = true);
    },
  },
  mounted() {
    this.$root.$on("toggle-drawer", (drawer) => {
      this.drawer = drawer;
    });
  },
  methods: {
    /**
     * Opens the logout modal by calling the openModal method on the logout modal reference.
     *
     * @method openLogoutModal
     */
    openLogoutModal() {
      this.$refs.logoutModalRef.openModal();
    },
  },
};
</script>

<style>
.list-item-content-container {
  display: flex;
  flex-direction: column;
}

.api-documentation-heading {
  margin-bottom: 0;
}

.icon-container {
  margin-top: 0;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-navigation-drawer__border {
  border-right: 1px solid #eff1f8 !important;
}

.flat {
  text-decoration: none !important;
}

.router-link a {
  color: #292b30 !important;
}

.list-item-active {
  /* border-right: 1px solid var(--v-primary-base) !important; */
  max-width: 200px !important;
  border-radius: 4px !important;
  background: #eeeef3 !important;
  /* color: var('primary--text'); */
}

.remove-hover::before {
  background-color: #f8f8f8 !important;
}

.sectionText {
  color: #979797;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  /* 166.667% */
  padding-left: 12px !important;
}

.custom-list-item {
  width: 196px;
  height: 30px;
  flex-shrink: 0;
}

.doc-link {
  text-decoration: none;
  color: #979797 !important;
  font-family: Inter;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  /* 166.667% */
}

.sidebar-title {
  color: #292b30;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.menu-btn {
  position: fixed !important;
  left: 200px;
  top: 15px;
  z-index: 10;
  background: #fff;
  stroke-width: 1px;
  stroke: var(--Strock, #eaeaea);
}

.menu-btn-mini {
  position: fixed !important;
  left: 40px;
  top: 15px;
  z-index: 10;
  background: #fff;
  stroke-width: 1px;
  stroke: var(--Strock, #eaeaea);
}

.list-item-active .sidebar-title {
  color: #213cc8 !important;
}
.list-item-active .icon {
  max-width: 48px !important;
  /* margin:4px !important; */
  display: flex;
  justify-content: normal;
  align-items: center;
}
/* .mini-item{
  max-width: 48px !important;
  display: flex;
  justify-content: normal;
  align-items: center;
} */

.list-item-active .side-icon {
  filter: invert(29%) sepia(99%) saturate(2286%) hue-rotate(224deg)
    brightness(74%) contrast(120%);
}
/* .list-item-active .icon {
  display: flex;
  justify-content: normal;
  align-items: center;
  border-radius: 4px !important;
  max-width: min-content;
} */
.icon {
  display: flex;
  justify-content: normal;
  align-items: center;
  margin: 4px 6px 4px 4px !important;
}

::-webkit-scrollbar {
  display: none;
}

.divider-class {
  stroke-width: 1px;
  stroke: #e3e3e3;
}

.logo-img {
  margin: 14px 54px 12px 47px !important;
}
.v-list-item__icon {
  margin-right: 10px !important;
}
.v-navigation-drawer {
  -webkit-overflow-scrolling: touch;
}
.v-navigation-drawer {
  overflow-y: auto;
}
</style>