<template>
  <div>
    <v-card color="appBackGround" elevation="0" style="min-height: 730px">
      <v-card-title class="greyColor--text line-height font-15 fw-600 pa-6">
        Billing Details
        <v-spacer></v-spacer>
        <custom-date-filter
          @typeChanged="typeChanged"
          :type="type"
          @clearSearch="dateCleared"
          @dateChange="dateChanged"
          :billing="true"
          class="mb-1 mr-3"
        >
          <span
            class="text-capitalize"
            :class="name != null ? 'black--text' : ''"
          >
            {{ name === null ? "Select Date Range" : name }}
          </span>
          <v-img class="calender_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="grey"
                d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5v-5Z"
              />
            </svg>
          </v-img>
        </custom-date-filter>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="textColor--text text-capitalize font-12 fw-500"
              color="primary"
              small
              elevation="0"
              href="mailto:support@facia.ai"
              target="_blank"
            >
              Contact Us
              <v-icon size="15" class="ml-2">mdi-forum</v-icon>
            </v-btn>
          </template>
          <span>Share query with Facia at (support@facia.ai)</span>
        </v-tooltip>
      </v-card-title>
      <!-- Data Table -->
      <data-table
        :customHeaders="headers"
        :data="Listing"
        :loading="isLoading"
      ></data-table>
      <!-- Pagination -->
      <div v-if="total > 10" class="pagination-controls">
        <span class="show-pagination-numbers"
          >Viewing {{ getViewingRange() }}</span
        >
        <div>
          <v-btn
            @click="prevPage"
            :disabled="paginate.page === 1"
            class="text-capitalize pervious-pagination-btn"
          >
            <v-icon size="18">mdi-chevron-left</v-icon>
            Previous
          </v-btn>
          <v-btn
            @click="nextPageButton"
            :disabled="paginate.page === lastPage"
            class="next-pagination-btn ml-2 text-capitalize"
          >
            Next
            <v-icon size="18">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import CustomDateFilter from "../common/filters/CustomDateFilter.vue";
import DataTable from "../common/DataTable.vue";
import { showErrorMessage } from "../../utils/showError";
export default {
  name: "BillingTab",
  components: { CustomDateFilter, DataTable },
  data() {
    return {
      btnLoader: false,
      isLoading: false,
      loading: false,
      name: "this month",
      startDate: null,
      endDate: null,
      type: "this_month",
      paginate: {
        page: 1,
        itemsPerPage: 10,
      },
      total: null,
      lastPage: null,
      dateFilters: [
        { name: "This Month", value: "this_month" },
        { name: "Last month", value: "last_month" },
        { name: "Last 3 months", value: "three_month" },
        { name: "Last 6 months", value: "six_month" },
        { name: "Last year", value: "last_year" },
        { name: "All time", value: "all_time" },
      ],
      Listing: [],
      headers: [
        {
          text: "Billing Month",
          value: "merchant_billing_date",
          sortable: true,
        },
        {
          text: "Total Verifications",
          align: "start",
          value: "merchant_monthly_verification",
        },
        {
          text: "Performed Verifications",
          value: "merchant_consumed_verification",
        },
        {
          text: "Remaining Verifications",
          value: "merchant_remaining_verification",
        },
      ],
    };
  },
  watch: {
    paginate: {
      handler() {
        this.getListing();
      },
      deep: true,
    },
    type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
  },

  mounted() {
    this.getListing();
  },

  methods: {
    /**
     * Fetches the billing statistics data.
     */
    getListing() {
      this.isLoading = true;
      let data = {
        pagination: this.paginate.itemsPerPage,
        page: this.paginate.page,
        type: this.type,
      };
      ApiService.PostRequest("/merchant/billing-stats", data)
        .then((response) => {
          this.Listing = response.data.result.data;
          this.total = response.data.result.total;
          this.lastPage = response.data.result.last_page;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },

    /**
     * Ensures data is fetched from the first page when the page changes.
     */
    getTransactionsOnPageBasis() {
      if (this.paginate.page !== 1) this.paginate.page = 1;
      else this.getListing();
    },

    /**
     * Handles pagination when navigating to the next page.
     */
    nextPageButton() {
      if (this.paginate.page < this.lastPage) {
        this.paginate.page += 1;
        this.getListing();
      }
    },

    /**
     * Handles pagination when navigating to the previous page.
     */
    prevPage() {
      if (this.paginate.page > 1) {
        this.paginate.page -= 1;
        this.getListing();
      }
    },

    /**
     * Calculates the viewing range based on pagination settings.
     * @returns {string} The viewing range text.
     */
    getViewingRange() {
      const start = (this.paginate.page - 1) * this.paginate.itemsPerPage + 1;
      const end = Math.min(start + this.paginate.itemsPerPage - 1, this.total);
      return `${end} out of ${this.total}`;
    },

    /**
     * Handles the change of date in the date picker.
     * @param {Date[]} changedDate - An array containing the start and end dates selected.
     */
    dateChanged(changedDate) {
      this.type = "custom_range";
      if (changedDate[0] <= changedDate[1]) {
        this.startDate = changedDate[0];
        this.endDate = changedDate[1];
      } else if (changedDate[0] >= changedDate[1]) {
        this.startDate = changedDate[1];
        this.endDate = changedDate[0];
      } else if (changedDate[1] == undefined) {
        this.startDate = changedDate[0];
        this.endDate = "";
      } else {
        (this.startDate = ""), (this.endDate = "");
      }
      this.name = this.startDate + " " + this.endDate;
      this.paginate.page = 1;
      this.getListing();
    },

    /**
     * Handles the change of type in the dropdown.
     * @param {object} type - The selected type object.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
      this.startDate = null;
      this.endDate = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears the selected date and type filters.
     */
    dateCleared() {
      this.name = null;
      this.type = null;
      this.getTransactionsOnPageBasis();
    },
  },
};
</script>

<style scoped>
.line-height {
  line-height: 3rem;
}
.text-field-filter .v-input__slot {
  width: 165px;
}
.calender_icon {
  padding-top: 5px;
  padding-left: 10px;
}
.pagination-controls {
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
}
.show-pagination-numbers {
  color: #292b30;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pervious-pagination-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.next-pagination-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
</style>
<style>
.v-menu__content {
  min-width: 160px !important;
}
</style>